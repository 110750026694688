<ion-item lines="none" class="payment-card-main-item" routerDirection="root" [routerLink]="route">
  <ion-row class="payment-cards-row">
    <ion-col size="12" class="payment-card-ion-body">
      <div>
        <ion-item lines="none" class="payment-card-ion-item"
          ><ion-text class="payment-card-title" color="primary">{{
            payment_card.name
          }}</ion-text></ion-item
        >

        <ion-item lines="none" class="payment-card-ion-item"
          ><ion-text class="payment-card-body" color="amber"
            >{{ payment_card.account.kiddy?.firstname }}
            {{ payment_card.account.kiddy?.lastname }}</ion-text
          ></ion-item
        >
        <ion-item lines="none" class="payment-card-ion-item">
          <ion-text class="payment-card-date" color="primary">
            {{ payment_card.creation_date | date : "mediumDate" }}
          </ion-text>
        </ion-item>
      </div>
      <div class="reward-div">
        <ion-item lines="none" class="payment-card-ion-item"
          ><ion-text class="reward-text">Type</ion-text></ion-item
        >
        <ion-item lines="none" class="payment-card-ion-item"
          ><ion-text class="reward-amount"
            >{{ payment_card.type }} :
            {{ payment_card.number }}</ion-text
          ></ion-item
        >
        <ion-item lines="none" class="payment-card-ion-item"
          ><ion-text class="reward-status">{{ payment_card.active }}</ion-text>
        </ion-item>
      </div>
    </ion-col>
  </ion-row>
</ion-item>
