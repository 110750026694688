import { Component, OnInit } from '@angular/core';
import { InfiniteScrollCustomEvent } from '@ionic/angular';

@Component({
  selector: 'kc-account-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss'],
})
export class AccountTransactionsListComponent implements OnInit {

  transactions: any[] = [];

  ngOnInit() {
    this.generateItems();
  }

  names: any[] = [
    'Joellen Whyborn',
    'Alick Folomkin',
    'Porty Croughan',
    'Brew Babe',
    'Jeromy Rathjen'
  ]

  status: any[] = [
    'Pending',
    'Approved',
    'Declined',
    'Cancelled',
    'Flagged'
  ]

  private generateItems() {
    const count: number = this.transactions.length + 1;
    for (let i = 0; i < 50; i++) {
      const transaction = {
        id:  window.crypto.randomUUID(),
        from_account: {
          id: window.crypto.randomUUID(),
          name: this.names[Math.floor(Math.random()*this.names.length)],
          number: Math.floor(Math.random() * (1000000000 - 100000000) + 100000000),
        },
        to_account: {
          id: window.crypto.randomUUID(),
          name: this.names[Math.floor(Math.random()*this.names.length)],
          number: Math.floor(Math.random() * (1000000000 - 100000000) + 100000000),
        },
        amount: Math.floor(Math.random() * (10000 - 100) + 100),
        creationDate: new Date(), 
        status: this.status[Math.floor(Math.random()*this.status.length)]
      }
      this.transactions.push(transaction);
    }
    console.log('transactions', this.transactions)
  }

  onIonInfinite(ev: any) {
    this.generateItems();
    setTimeout(() => {
      (ev as InfiniteScrollCustomEvent).target.complete();
    }, 2000);
  }

}
