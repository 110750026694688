import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { InvestmentListItemComponent } from './list-item/list-item.component';
import { CardComponent } from './card/card.component';

@NgModule({
  declarations: [InvestmentListItemComponent, CardComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatePipe, 
    IonicModule, 
  ],
  exports: [InvestmentListItemComponent, CardComponent]
})
export class InvestmentModule { }
