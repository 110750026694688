import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  // GET Requests
  getAccountInfo(account_number: string, account_type: string) {
    return this.http.get<any>(`${environment.dataBaseUrl}/account/info/${account_number}/${account_type}`);
  }

  getAccountTransactionCodeInfo(account_number: string, account_type: string, code: string) {
    return this.http.get<any>(`${environment.dataBaseUrl}/account/info/${account_number}/${account_type}/${code}`);
  }

}
