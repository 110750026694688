import { Component, Input, Output, EventEmitter } from '@angular/core';
import type { OnInit } from '@angular/core';
import { SchoolInterface } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/services';

@Component({
  selector: 'kc-select-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss'],
})
export class SelectSchoolComponent  implements OnInit {
  @Input() schools: SchoolInterface[] = [];
  @Input() selectedSchool: SchoolInterface | undefined;
  @Input() title = 'Select School';

  @Output() selectionCancel = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<SchoolInterface>();
  
  staticBaseUrl: string =  environment.staticBaseUrl;

  filteredSchools: SchoolInterface[] = [];
  workingSelectedSchool: SchoolInterface | undefined;

  constructor(private toastService: ToastService) { }

  ngOnInit() {
    this.filteredSchools = [...this.schools];
  }
  
  trackSchools(index: number, school: SchoolInterface) {
    return school.id;
  }

  cancelChanges() {
    this.selectionCancel.emit();
  }
  
  confirmChanges() {
    if(!this.workingSelectedSchool){
      this.toastService.presentToast('Please select a school first', 'warning')
    } else {
      this.selectionChange.emit(this.workingSelectedSchool);
    }
  }
  
  searchbarInput(ev: any) {
    this.filterList(ev.target.value);
  }
  
  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  filterList(searchQuery: string | undefined) {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined) {
      this.filteredSchools = [...this.schools];
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase(); 
      this.filteredSchools = this.schools.filter(school => {
        return school.name.toLowerCase().includes(normalizedQuery);
      });
    }
  }

  radioChange(ev: any) {
    const school_id = ev.detail.value;

    const selectedSchool = this.schools.find((school: SchoolInterface) => school.id === school_id);
    if(selectedSchool) this.workingSelectedSchool = selectedSchool;
  }

}
