import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartLineComponent } from './line/line.component';

@NgModule({
  declarations: [ChartLineComponent],
  imports: [
    CommonModule
  ],
  exports: [
    ChartLineComponent
  ]
})
export class ChartModule { }
