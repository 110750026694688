<section class="timeline" *ngIf="activities && activities.length">
  <ul>
    <li *ngFor="let activity of activities">
      <div class="content">
        <time>{{ activity.date | date:'longDate' }}</time>
        <p>
          {{ activity.event | uppercase }}
        </p>
      </div>
    </li>
  </ul>
</section>