import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RolesEnum, UserInterface } from '@kiddy-cash/common';
import { AuthService, ToastService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class FamilyRoleGuard implements CanActivate {
  constructor(
    private authService: AuthService, 
    private router: Router, 
    private toastService: ToastService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      return new Promise((resolve) => {
        this.authService._$user.subscribe((user: UserInterface | null | undefined) => {
          if(user){
            const roleCustodian = user.profile.profile_roles.find(profile_role => profile_role.role.title === RolesEnum.Custodian)
            if(!roleCustodian) {
              // Disallow the route
              resolve(false);

              this.toastService.presentToast('Sorry, you cannot access that page', 'danger');

              // then navigate to dashboard
              this.router.navigate(['/dashboard']);
            } else {
              // Allow the route
              resolve(true);
            }
          } else {
            resolve(false);
          }
        });
      });
  }
  
}
