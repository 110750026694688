import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KiddyBusinessService {
  constructor(private http: HttpClient) { }

  // GET Requests
  getAllKiddyBusinesses() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business`);
  }

  getKiddyBusinessesIds() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/ids`);
  }

  getKiddyBusinessesAccounts() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/account`);
  }

  getKiddyBusinessesTransactions() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/transaction`);
  }

  getKiddyBusiness(business_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}`);
  }

  getKiddyBusinessAccounts(business_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/account`);
  }

  getKiddyBusinessTransactions(business_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/transaction`);
  }

  getKiddyBusinessTransactionCodes(business_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/transaction-code`);
  }

  getKiddyBusinessTransactionCode(business_id: string, transaction_code_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/transaction-code/${transaction_code_id}`);
  }

  getActiveKiddyBusinessAccountIntegration(business_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/account/${account_id}/integration`);
  }

  getCountryIntegrationForKiddyBusinessAccount(business_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/account/${account_id}/country_integration`);
  }

  getKiddyBusinessAccount(business_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/account/${account_id}`);
  }

  getKiddyBusinessAllowances(business_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/allowance`);
  }

  getKiddyBusinessAccountAllowances(business_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/account/${account_id}/allowance`);
  }

  getKiddyBusinessAccountAllowance(account_id: string, allowance_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/allowance/${allowance_id}`);
  }

  getKiddyBusinessGoals() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/goal`);
  }

  getKiddyBusinessAccountGoals(account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/goal`);
  }

  getKiddyBusinessAccountGoal(account_id: string, goal_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/goal/${goal_id}`);
  }

  getKiddyBusinessInvestments() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/investment`);
  }

  getKiddyBusinessAccountInvestments(account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/investment`);
  }

  getKiddyBusinessAccountInvestment(account_id: string, investment_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/investment/${investment_id}`);
  }

  getKiddyBusinessLoans() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/loan`);
  }

  getKiddyBusinessAccountLoans(account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/loan`);
  }

  getKiddyBusinessAccountLoan(account_id: string, loan_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/loan/${loan_id}`);
  }

  getKiddyBusinessTasks() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/task`);
  }

  getKiddyBusinessAccountTasks(account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/task`);
  }

  getKiddyBusinessAccountTask(account_id: string, task_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/task/${task_id}`);
  }

  getKiddyBusinessAccountTransactions(business_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/account/${account_id}/transaction`);
  }

  getKiddyBusinessAccountTransaction(business_id: string, account_id: string, transaction_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/account/${account_id}/transaction/${transaction_id}`);
  }

  getKiddyBusinessPaymentCards() {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/payment_card`);
  }

  getKiddyBusinessAccountPaymentCards(account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/payment_card`);
  }

  getKiddyBusinessAccountPaymentCard(account_id: string, payment_card_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/payment_card/${payment_card_id}`);
  }

  //POST Requests
  createKiddyBusiness(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-business`, data);
  }

  createKiddyBusinessAccount(business_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/account`, data);
  }

  createKiddyBusinessTransactionCode(business_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/transaction-code`, data);
  }

  createKiddyBusinessAccountIntegration(business_id: string, account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/account/${account_id}/integration`, data);
  }

  goalForKiddyBusinessAccount(account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/goal`, data);
  }

  depositSavingGoalForKiddyBusinessAccount(account_id: string, goal_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/goal/${goal_id}/deposit`, data);
  }

  withdrawSavingGoalForKiddyBusinessAccount(account_id: string, goal_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/goal/${goal_id}/withdraw`, data);
  }

  investmentForKiddyBusinessAccount(account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/investment`, data);
  }

  depositInvestmentForKiddyBusinessAccount(account_id: string, goal_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/investment/${goal_id}/deposit`, data);
  }

  withdrawInvestmentForKiddyBusinessAccount(account_id: string, goal_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/investment/${goal_id}/withdraw`, data);
  }

  payLoanForKiddyBusinessAccount(account_id: string, loan_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/loan/${loan_id}/pay`, data);
  }

  transactionForKiddyBusinessAccount(account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/transaction`, data);
  }

  reviewTransactionForKiddyBusinessAccount(account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/transaction/review`, data);
  }

  //Update Requests
  updateKiddyBusiness(business_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}`, data);
  }

  updateKiddyBusinessAccount(business_id: string, business_account_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/account/${business_account_id}`, data);
  }

  uploadKyb(business_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/know-your-business`, data);
  }

  updateKiddyBusinessTransactionCode(business_id: string, transaction_code_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/transaction-code/${transaction_code_id}`, data);
  }

  updateAllowanceForKiddyBusinessAccount(account_id: string, allowance_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/allowance/${allowance_id}`, data);
  }

  updateGoalForKiddyBusinessAccount(account_id: string, goal_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/goal/${goal_id}`, data);
  }

  updateInvestmentForKiddyBusinessAccount(account_id: string, investment_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/investment/${investment_id}`, data);
  }

  updateLoanForKiddyBusinessAccount(account_id: string, loan_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/loan/${loan_id}`, data);
  }

  updateTaskForKiddyBusinessAccount(account_id: string, task_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/task/${task_id}`, data);
  }

  updateKiddyBusinessAccountTaskStatus(account_id: string, task_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/task/${task_id}/status`, data);
  }
  
  updateTransactionForKiddyBusinessAccount(account_id: string, transaction_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/transaction/${transaction_id}`, data);
  }

  updatePaymentCardForKiddyBusinessAccount(account_id: string, payment_card_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/kiddy-business/account/${account_id}/payment_card/${payment_card_id}`, data);
  }

  // Delete Requests
  closeKiddyBusinessAccount(business_id: string, account_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/account/${account_id}`);
  }

  removeKiddyBusinessManagers(business_id: string, data: any) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/kiddy-business/${business_id}/managers`, {
      body: data
    });
  }

}