<ion-item lines="none" class="investment-main-item" routerDirection="root" [routerLink]="route">
  <ion-row class="investments-row">
    <ion-col size="12" class="investment-ion-body">
      <div class="investment-sub">
        <div>
          <ion-item lines="none" class="investment-ion-item">
            <ion-thumbnail aria-hidden="true" slot="start">
              <img width="56" alt="" src="{{ staticBaseUrl + '/stocks/' + investment.stock.ticker + '.png'}}" />
            </ion-thumbnail>

            <ion-text class="investment-title" color="primary">
              {{ investment.stock.name }}
            </ion-text>
          </ion-item>

          <ion-item lines="none" class="investment-ion-item"
            ><ion-text class="investment-body" color="amber"
              ><span class="investment-text">Investment by</span>
              {{ investment.kiddy_account.kiddy?.firstname }}
              {{ investment.kiddy_account.kiddy?.lastname }}</ion-text
            ></ion-item
          >
        </div>
        <div class="reward-div">
          <ion-text class="reward-text">Value</ion-text>

          <ion-text class="reward-amount"
            >{{ investment.kiddy_account.country.currency.code }}
            {{ investment.value | number:'1.0-2' }}</ion-text
          >
        </div>
      </div>
      <div class="investment-sub-bottom">
        <div class="investment-ion-item">
          <ion-text class="investment-date" color="primary"
            >{{ investment.notify }}</ion-text
          >
          <ion-text class="description-bottom">Reminder</ion-text>
        </div>
        <div class="investment-ion-item">
          <ion-text class="investment-date" color="primary"
            >{{ investment.kiddy_account.country.currency.code }}
            {{ investment.value | number:'1.0-2' }}</ion-text
          >
          <ion-text class="description-bottom">Amount</ion-text>
        </div>
        <div class="investment-ion-item">
          <ion-text class="investment-date" color="primary"
            >
            {{ investment.stock.exchange }}:{{ investment.stock.ticker }}</ion-text
          >
          <ion-text class="description-bottom">Stock Ticker</ion-text>
        </div>
        <div class="investment-ion-item">
          <ion-text class="investment-date" color="primary">{{
            investment.creation_date | date : "mediumDate"
          }}</ion-text>
          <ion-text class="description-bottom">Invested On</ion-text>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-item>
