import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationFormComponent } from './registration-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SelectModule } from '../select/select.module';
import { MaskitoDirective, MaskitoPipe } from '@maskito/angular';

@NgModule({
  declarations: [RegistrationFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IonicModule,
    SelectModule,
    MaskitoDirective, 
    MaskitoPipe, 
  ],
  exports: [
    RegistrationFormComponent
  ]
})
export class RegistrationFormModule { }
