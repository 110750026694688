import { Component, Input, OnInit } from '@angular/core';
import { StockHistoryInterface, StockInterface } from '@kiddy-cash/common';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
Chart.register(...registerables);

@Component({
  selector: 'kc-chart-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss'],
})
export class ChartLineComponent  implements OnInit {
  @Input() stock!: StockInterface;
  @Input() stockHistory: StockHistoryInterface[] = [];

  constructor() { }

  ngOnInit() {
    const chartLabel: Date[] = [];
    const chartData: number[] = [];

    for (let history of this.stockHistory) {
      chartLabel.push(history.datetime)
      chartData.push(history.close);
    }

    const totalDuration = 5 * chartData.length; // 5ms per each data entry
    const delayBetweenPoints = totalDuration / chartData.length;
    const previousY = (ctx: any) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;

    const animationX: any = {
        type: 'number',
        easing: 'linear',
        duration: delayBetweenPoints,
        from: NaN, // the point is initially skipped
        delay(ctx: any) {
          if (ctx.type !== 'data' || ctx.xStarted) {
            return 0;
          }
          ctx.xStarted = true;
          return ctx.index * delayBetweenPoints;
        }
    };

    const animationY: any = {
      type: 'number',
      easing: 'linear',
      duration: delayBetweenPoints,
      from: previousY,
      delay(ctx: any) {
        if (ctx.type !== 'data' || ctx.yStarted) {
          return 0;
        }
        ctx.yStarted = true;
        return ctx.index * delayBetweenPoints;
      }
    };

    const animation: any = {
      x: animationX,
      y: animationY,
    };

    const chart = new Chart("myChart", {
      type: 'line',
      data: {
        labels: chartLabel,
        datasets: [{
          data: chartData,
          borderWidth: 0.5,
          pointRadius: 0,
        }],
      },
      options: {
        animation: animation,
        interaction: {
          intersect: false
        },
        plugins: {
          legend: {
            display: false,
          }
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            type: 'linear',
          },
          x: {
            type: 'time',
          },
        }
      }
    });
  }

}
