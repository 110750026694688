import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SchoolCardComponent } from './card/card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [SchoolCardComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatePipe, 
    IonicModule, 
  ],
  exports: [SchoolCardComponent],
})
export class SchoolModule { }
