<form [formGroup]="familyAllowanceEditForm" (ngSubmit)="submitForm()">
    <ion-card mode="md">
      <ion-fab horizontal="end" *ngIf="isGuardian">
        <ion-fab-button size="small" color="warning" *ngIf="!isEditting" (click)="startEditing()">
          <ion-icon name="create"></ion-icon>
        </ion-fab-button>
        <ion-fab-button size="small" color="danger" *ngIf="isEditting" (click)="stopEditing()">
          <ion-icon name="close"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-card-header>
        <ion-card-title>
          <ng-container *ngIf="!isEditting">
            {{ allowance.purpose }}
          </ng-container>
          <ng-container *ngIf="isEditting">
            <ion-list>
              <ion-item lines="none">
                <ion-input
                  type="text"
                  label="Purpose"
                  labelPlacement="floating"
                  formControlName="purpose"
                  [counter]="true" 
                  minlength="3"
                  maxlength="100"
                  [value]="allowance.purpose"
                ></ion-input>
              </ion-item>
            </ion-list>
          </ng-container>
        </ion-card-title>
        <ion-card-subtitle>
          <ng-container *ngIf="!isEditting">
            {{ allowance.from_account.country.currency.code }} {{ allowance.amount | number:'1.0-2' }}
          </ng-container>
          <ng-container *ngIf="isEditting">
            <ion-list>
              <ion-item lines="none">
                <ion-input
                  type="number"
                  label="Amount in {{ allowance.from_account.country.currency.symbol }}"
                  labelPlacement="floating"
                  formControlName="amount"
                  [value]="allowance.amount"
                ></ion-input>
              </ion-item>
            </ion-list>
          </ng-container>
        </ion-card-subtitle>
      </ion-card-header>
  
      <ion-card-content>
        <ion-grid class="ion-no-padding" *ngIf="!isEditting">
          <ion-row *ngIf="isGuardian">
            <ion-col size="12">
                <ion-list>
                    <ion-item lines="none" class="ion-no-padding">
                        <ion-avatar slot="start">
                            <img 
                                alt="avatar" 
                                src="{{ allowance.to_account.kiddy?.avatarversion ? ( staticBaseUrl + '/profiles/' + allowance.to_account.kiddy?.id + '/avatars/' + allowance.to_account.kiddy?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/avatar.svg' ) }}"/>
                        </ion-avatar>
                        <ion-label>{{ allowance.to_account.kiddy?.firstname }} {{ allowance.to_account.kiddy?.lastname}}</ion-label>
                    </ion-item>
                </ion-list>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="6" size-md="6" size-lg="6">
              <ion-list>
                <ion-list-header>
                  <ion-label>Frequency</ion-label>
                </ion-list-header>
                <ion-item>
                  {{ allowance.frequency }}
                </ion-item>
              </ion-list>
            </ion-col>
    
            <ion-col size="6" size-md="6" size-lg="6">
              <ion-list>
                <ion-list-header>
                  <ion-label>Start On</ion-label>
                </ion-list-header>
                <ion-item>
                  {{ allowance.start_on | date: 'longDate' }}
                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="6" size-md="6" size-lg="6">
              <ion-list>
                <ion-list-header>
                  <ion-label>Status</ion-label>
                </ion-list-header>
                <ion-item>
                  {{ allowance.status }}
                </ion-item>
              </ion-list>
            </ion-col>
    
            <ion-col size="6" size-md="6" size-lg="6">
              <ion-list>
                <ion-list-header>
                  <ion-label></ion-label>
                </ion-list-header>
                <ion-item>

                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid class="ion-no-padding" *ngIf="isEditting">
          <ion-row>
              <ion-col size="12">
                <ion-list>
                  <ion-item lines="none">
                  <ion-select label="Frequency" label-placement="floating" value="{{ allowance.frequency }}">
                    <ion-select-option *ngFor="let frequency of AllowanceFrequencyEnumValues" [value]="frequency">{{ frequency }}</ion-select-option>
                  </ion-select>
                  </ion-item>
                </ion-list>
              </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="12">
              <ion-list>
                <ion-item lines="none">
                  <ion-input
                    type="date"
                    label="Start Date"
                    labelPlacement="floating"
                    formControlName="start_on"
                    [value]="allowance.start_on.toString()"
                  ></ion-input>
                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="12">
              <ion-list>
                <ion-item lines="none">
                  <ion-select label="Status" label-placement="floating" value="{{ allowance.status }}">
                    <ion-select-option *ngFor="let status of AllowanceStatusEnumValues" [value]="status">{{ status }}</ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
  
      <ion-button *ngIf="isEditting" type="submit" expand="block" color="darkpurple">Update</ion-button>
    </ion-card>
  </form>
  