<ion-card>
  <ion-card-header>
    <ion-card-title>{{ school.name }}</ion-card-title>
    <ion-card-subtitle>{{ school.country.name }}</ion-card-subtitle>
  </ion-card-header>

  <ion-card-content>
    <ion-text>
      {{ school.description }}
    </ion-text>
  </ion-card-content>
</ion-card>
