import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { PaymentCardCardComponent } from './card/card.component';
import { PaymentCardListItemComponent } from './list-item/list-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PaymentCardCardComponent, PaymentCardListItemComponent],
  exports: [PaymentCardCardComponent, PaymentCardListItemComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatePipe, 
    IonicModule, 
  ]
})
export class PaymentCardModule { }
