import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SelectCountryComponent } from './country/country.component';
import { SelectStockComponent } from './stock/stock.component';
import { SelectSchoolComponent } from './school/school.component';

@NgModule({
  declarations: [SelectCountryComponent, SelectStockComponent, SelectSchoolComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [SelectCountryComponent, SelectStockComponent, SelectSchoolComponent]
})
export class SelectModule { }
