<form [formGroup]="familyTaskEditForm" (ngSubmit)="submitForm()">
  <ion-card mode="md">
    <ion-fab horizontal="end" *ngIf="isGuardian">
      <ion-fab-button size="small" color="warning" *ngIf="!isEditting" (click)="startEditing()">
        <ion-icon name="create"></ion-icon>
      </ion-fab-button>
      <ion-fab-button size="small" color="danger" *ngIf="isEditting" (click)="stopEditing()">
        <ion-icon name="close"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-card-header>
      <ion-card-title>
        <ng-container *ngIf="!isEditting">
          {{ task.title }}
        </ng-container>
        <ng-container *ngIf="isEditting">
          <ion-list>
            <ion-item lines="none">
              <ion-input
                type="text"
                label="Title"
                labelPlacement="floating"
                formControlName="title"
                [counter]="true" 
                minlength="3"
                maxlength="100"
                [value]="task.title"
              ></ion-input>
            </ion-item>
          </ion-list>
        </ng-container>
      </ion-card-title>
      <ion-card-subtitle>
        <ng-container *ngIf="!isEditting">
          {{ task.from_account.country.currency.code }} {{ task.reward | number:'1.0-2' }}
        </ng-container>
        <ng-container *ngIf="isEditting">
          <ion-list>
            <ion-item lines="none">
              <ion-input
                type="number"
                label="Reward in {{ task.from_account.country.currency.symbol }}"
                labelPlacement="floating"
                formControlName="reward"
                [value]="task.reward"
              ></ion-input>
            </ion-item>
          </ion-list>
        </ng-container>
      </ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      <ion-grid class="ion-no-padding" *ngIf="!isEditting">
        <ion-row>
          <ion-col size="12">
            <ion-list>
              <ion-list-header>
                <ion-label>Description</ion-label>
              </ion-list-header>
              <ion-item>
                {{ task.description }}
              </ion-item>
            </ion-list>   
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Frequency</ion-label>
              </ion-list-header>
              <ion-item>
                {{ task.frequency }}
              </ion-item>
            </ion-list>   
          </ion-col>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Updated</ion-label>
              </ion-list-header>
              <ion-item>
                {{ task.updated_on | date: 'longDate' }}
              </ion-item>
            </ion-list>   
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-list>
              <ion-list-header>
                <ion-label>Change Status</ion-label>
              </ion-list-header>
              <ion-item>
                <ion-select value="{{ task.status }}" (ionChange)="taskStatusSelectChange($event)">
                  <ion-select-option *ngFor="let status of TaskStatuses" [value]="status">{{ status }}</ion-select-option>
                </ion-select>  
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid class="ion-no-padding" *ngIf="isEditting">
        <ion-row>
          <ion-col size="12">
            <ion-list>
              <ion-item lines="none">
                <ion-textarea
                  fill="solid"
                  label="Task Description"
                  labelPlacement="floating"
                  helperText="Optional | Describe any details of this task"
                  errorText="Invalid task description"
                  counter="true"
                  minlength="2"
                  maxlength="400"
                  formControlName="description"
                  [value]="task.description"
                  ></ion-textarea>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-list>
              <ion-item lines="none">
                <ion-select label="Frequency" label-placement="floating" value="{{ task.frequency }}">
                  <ion-select-option *ngFor="let frequency of TaskFrequencyEnumValues" [value]="frequency">{{ frequency }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-list>
              <ion-item lines="none">
                <ion-select label="Notifications Frequency" label-placement="floating" value="{{ task.notify }}">
                  <ion-select-option *ngFor="let notify of NotifyFrequencyEnumValues" [value]="notify">{{ notify }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>

    <ion-button *ngIf="isEditting" type="submit" expand="block" color="darkpurple">Update</ion-button>
  </ion-card>
</form>
