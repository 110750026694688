import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CountryInterface, ProfileGenderIdentityEnum, UserInterface } from '@kiddy-cash/common';
import { AuthService, CountryService, ToastService, VerifyService } from 'src/app/services';
import { WhiteSpaceValidator } from 'src/shared/directives/whitespace-validator';
import { maskitoPhoneOptionsGenerator } from '@maskito/phone';
import metadata from 'libphonenumber-js/mobile/metadata';
import { MaskitoOptions, MaskitoElementPredicate } from "@maskito/core";
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kc-child-information',
  templateUrl: './child-information.component.html',
  styleUrls: ['./child-information.component.scss'],
})
export class ChildInformationComponent implements OnInit {
  selectedCountry: CountryInterface | undefined;

  name!: string;
  ProfileGenderIdentityEnumValues = Object.values(ProfileGenderIdentityEnum);

  isPhoneAuth: boolean = true;
  isEmailAuth: boolean = false;
  isCodeSent: boolean = false;
  isCodeVerified: boolean = false;
  isProcessing: boolean = false;
  isReadonly: boolean = false;

  childForm: FormGroup = this.formBuilder.group({
    childId: [null],
    phone: [undefined],
    email: [undefined, Validators.email],
    code: ['', Validators.required],
    firstname: ['', [Validators.required, WhiteSpaceValidator.containsOnlySpaces]],
    lastname: ['', [Validators.required, WhiteSpaceValidator.containsOnlySpaces]],
    genderidentity: ['', Validators.required],
    pin: ['', [
        Validators.required, 
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(4),
        Validators.maxLength(4),
      ]
    ],
    dob: ['', Validators.required],
    valid: [null]
  })

  child: any;
  staticBaseUrl: string = environment.staticBaseUrl

  readonly maskPredicate: MaskitoElementPredicate = async el => (el as HTMLIonInputElement).getInputElement();
  public maskitoPhoneOptions!: MaskitoOptions;

  constructor(
    private modalCtrl: ModalController, 
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    private toastService: ToastService,
    private verifyService: VerifyService,
    ) {}

  ngOnInit() {
    this.childForm.patchValue({
      childId: this.child.childId,
      phone: this.child.phone,
      email: this.child.email,
      code: this.child.code,
      firstname: this.child.firstname,
      lastname: this.child.lastname,
      genderidentity: this.child.genderidentity,
      pin: this.child.pin,
      dob: this.child.dob,
      valid: this.child.valid
    })

    this.countryService._$selectedCountry.subscribe((country: CountryInterface | undefined) => {
      if(!country) return;
      this.selectedCountry = country;

      const iso: CountryCode = country.iso2 as CountryCode
      this.maskitoPhoneOptions = maskitoPhoneOptionsGenerator({
        strict: true,
        countryIsoCode: iso, 
        metadata,
      });
    });
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    this.childForm.value.valid = true
    return this.modalCtrl.dismiss(this.childForm.value, 'confirm');
  }

  countrySelectionChanged(country: CountryInterface) {
    this.countryService._$selectedCountry.next(country)
  }

  toggleAuthType(authType: string){
    if(authType === 'phone') {
      this.isPhoneAuth = true;
      this.isEmailAuth = false;
      this.childForm.get('email')?.reset(undefined);
    } else {
      this.isPhoneAuth = false;
      this.isEmailAuth = true;
      this.childForm.get('phone')?.reset(undefined);
    }
  }

  sendCode(){
    if(this.isPhoneAuth) {
      const phone =  this.childForm.value.phone;
      if(this.childForm.get('phone')?.valid !== true) return;

      const parsedPhoneNumber = parsePhoneNumber(phone)
      const phoneData = parsedPhoneNumber?.number as string;

      if(!parsedPhoneNumber?.isValid()) {
        this.toastService.presentToast('Invalid Phone Number', "danger");
        return;
      }

      this.isProcessing = true;
      this.verifyService.requestPhoneOTP(phoneData).subscribe((res: any) => {
        this.isProcessing = false;
        this.isCodeSent = true;
        this.isCodeVerified = false;
        if(res.data?.code) this.childForm.patchValue({code: res.data.code});
      },
      (err: any) => {
        this.isProcessing = false;
      })
    }

    if(this.isEmailAuth) {
      const email =  this.childForm.value.email;
      if(this.childForm.get('email')?.valid !== true) return;

      this.isProcessing = true;
      this.verifyService.requestEmailOTP(email).subscribe((res: any) => {
        this.isProcessing = false;
        this.isCodeSent = true;
        this.isCodeVerified = false;  
        if(res.data?.code) this.childForm.patchValue({code: res.data.code});
      },
      (err: any) => {
        this.isProcessing = false;
      })
    }
  }

  verifyCode(){
    if(this.childForm.get('code')?.valid !== true) return;

    const code =  this.childForm.value.code;

    if(this.isPhoneAuth) {
      const phone =  this.childForm.value.phone;
      if(this.childForm.get('phone')?.valid !== true) return;

      const parsedPhoneNumber = parsePhoneNumber(phone)
      const phoneData = parsedPhoneNumber?.number as string;

      this.isProcessing = true;
      this.verifyService.verifyPhoneOTP(phoneData, code).subscribe((res: any) => {
        const user: UserInterface = res.data.user;

        if(user){
          this.childForm.patchValue({
            firstname: user.profile.firstname,
            lastname: user.profile.lastname,
            genderidentity: user.profile.genderidentity,
            dob: user.profile.dob,
            pin: '0000',
          });
          this.isReadonly = true;
          this.childForm.get('genderidentity')?.disable()
        }

        this.isProcessing = false;
        this.isCodeSent = true;
        this.isCodeVerified = true;
      },
      (err: any) => {
        this.isProcessing = false;
      })
    }

    if(this.isEmailAuth) {
      const email =  this.childForm.value.email;
      if(this.childForm.get('email')?.valid !== true) return;

      this.isProcessing = true;
      this.verifyService.verifyEmailOTP(email, code).subscribe((res: any) => {
        const user: UserInterface = res.data.user;

        if(user){
          this.childForm.patchValue({
            firstname: user.profile.firstname,
            lastname: user.profile.lastname,
            genderidentity: user.profile.genderidentity,
            dob: user.profile.dob,
            pin: '0000',
          });
          this.isReadonly = true;
          this.childForm.get('genderidentity')?.disable()
        }

        this.isProcessing = false;
        this.isCodeSent = true;
        this.isCodeVerified = true;  
      },
      (err: any) => {
        this.isProcessing = false;
      })
    }
  }
  
}
