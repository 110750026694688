import { Component, Input, OnInit } from '@angular/core';
import { SchoolInterface } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kc-school-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class SchoolCardComponent  implements OnInit {
  @Input() school!: SchoolInterface;

  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor() { }

  ngOnInit() {}

}
