import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ListItemComponent } from "./list-item/list-item.component";
import { IonicModule } from "@ionic/angular";
import { RouterModule } from "@angular/router";
import { CardComponent } from "./card/card.component";

@NgModule({
  declarations: [ListItemComponent, CardComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatePipe, 
    IonicModule, 
  ],
  exports: [ListItemComponent, CardComponent],
})
export class AllowanceModule {}
