import { inject, Injectable } from '@angular/core';
import { UserInterface } from '@kiddy-cash/common';
import { AuthService } from '../auth/auth.service';
import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { FirebasePerformance } from '@capacitor-firebase/performance';
import { Analytics, logEvent, setUserId, setCurrentScreen } from '@angular/fire/analytics';
import { Performance, trace } from '@angular/fire/performance';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  user: UserInterface | null | undefined;
  private webAnalytics: Analytics = inject(Analytics);
  private webPerformance: Performance = inject(Performance);

  constructor(
    private authService: AuthService,
  ) { }

  public async setUp() {
    this.authService._$user.subscribe((user: UserInterface | null | undefined) => {
      this.user = user;

      if(user?.id) this.crashlyticsSetUserId(user.id)
    });
  }

  // Firebase Both Methods
  crashlyticsCrash = async () => {
    if(Capacitor.isNativePlatform()) await this.appCrashlyticsCrash('Test' )
  };
  crashlyticsSetUserId = async (user_id: string) => {
    if(Capacitor.isNativePlatform()) await this.appCrashlyticsSetUserId(user_id)
  };
  analyticsLogEvent = async (name: string, params: any) => {
    if(Capacitor.isNativePlatform()){
      this.appAnalyticsLogEvent(name, params)
    } else {
      this.webAnalyticsLogEvent(name, params)
    }
  };

  performanceStartTrace = async (traceName: string) => {
    if(Capacitor.isNativePlatform()){
      this.appPerformanceStartTrace(traceName)
    } else {
      this.webPerformanceStartTrace(traceName)
    }
  };

  performanceTraceMetric = async (traceName: string, metricName: string, metricValue: number) => {
    if(Capacitor.isNativePlatform()){
      this.appPerformanceIncrementMetric(traceName, metricName, metricValue)
    } else {
      this.webPerformancePutMetric(traceName, metricName, metricValue)
    }
  };

  performanceStopTrace = async (traceName: string) => {
    if(Capacitor.isNativePlatform()){
      this.appPerformanceStopTrace(traceName)
    } else {
      this.webPerformanceStopTrace(traceName)
    }
  };

  // Firebase Web Methods
  private webAnalyticsCrash = async () => {

  };

  private webPerformanceStartTrace = async (traceName: string) => {
    const performanceTrace = trace(this.webPerformance, traceName);
    performanceTrace.start()
  };

  private webPerformancePutMetric = async (traceName: string, metricName: string, metricValue: number ) => {
    const performanceTrace = trace(this.webPerformance, traceName);
    performanceTrace.putMetric(metricName, metricValue);
  };

  private webPerformanceStopTrace = async (traceName: string) => {
    const performanceTrace = trace(this.webPerformance, traceName);
    performanceTrace.stop()
  };

  private webAnalyticsSetUserId = async (user_id: string) => {
    setUserId(this.webAnalytics, user_id);
  };
  
  private webAnalyticsSetUserProperty = async (key: string, value: string) => {

  };
  
  private webAnalyticsSetCurrentScreen = async (screenName: string) => {
    setCurrentScreen(this.webAnalytics, screenName);
  };
  
  private webAnalyticsLogEvent = async (name: string, params: any) => {
    logEvent(this.webAnalytics, name, params);
  };

  // Firebase App Methods
  private appCrashlyticsCrash = async (crash_message: string) => {
    await FirebaseCrashlytics.crash({ message: crash_message });
  };
  
  private appCrashlyticsSetCustomKey = async (key: string, value: string, type: "string" | "boolean" | "long" | "double" | "int" | "float") => {
    await FirebaseCrashlytics.setCustomKey({
      key: key,
      value: value,
      type: type,
    });
  };

  private appCrashlyticsSetUserId = async (userId: string) => {
    await FirebaseCrashlytics.setUserId({
      userId: userId,
    });
  };

  private appCrashlyticsLog = async (message: string) => {
    await FirebaseCrashlytics.log({
      message: message,
    });
  };

  private appCrashlyticsRecordException = async (message: string) => {
    await FirebaseCrashlytics.recordException({
      message: message,
    });
  };

  private appAnalyticsSetUserId = async (user_id: string) => {
    await FirebaseAnalytics.setUserId({
      userId: user_id,
    });
  };
  
  private appAnalyticsSetUserProperty = async (key: string, value: string) => {
    await FirebaseAnalytics.setUserProperty({
      key: key,
      value: value,
    });
  };
  
  private appAnalyticsSetCurrentScreen = async (screenName: string) => {
    await FirebaseAnalytics.setCurrentScreen({
      screenName: screenName,
    });
  };
  
  private appAnalyticsLogEvent = async (name: string, params: any) => {
    await FirebaseAnalytics.logEvent({
      name: name,
      params: params,
    });
  };

  private appResetAnalyticsData = async () => {
    await FirebaseAnalytics.resetAnalyticsData();
  };

  private appPerformanceStartTrace = async (traceName: string) => {
    await FirebasePerformance.startTrace({ traceName: traceName });
  };

  private appPerformanceStopTrace = async (traceName: string) => {
    await FirebasePerformance.stopTrace({ traceName: traceName });
  };

  private appPerformanceIncrementMetric = async (traceName: string, metricName: string, increment: number ) => {
    await FirebasePerformance.incrementMetric({
      traceName: traceName,
      metricName: metricName,
      incrementBy: increment,
    });
  };

}
