<ion-content color="background">
  <div class="container">
    <img [src]="staticBaseUrl + '/403/access-denied.png'" />

    <h2>
      <span>403</span> <br />
    </h2>
    <p class="info">
      <a class="btn-back" routerDirection="root" routerLink="..">Back to previous page</a>
    </p>
  </div>
</ion-content>
