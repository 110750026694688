import { Component, Input, OnInit } from "@angular/core";
import { LoanInterface } from "@kiddy-cash/common";

@Component({
  selector: "kc-loan-list-item",
  templateUrl: "./list-item.component.html",
  styleUrls: ["./list-item.component.scss"],
})
export class ListItemComponent implements OnInit {
  @Input() loan!: LoanInterface;
  @Input() route: (string | undefined)[] = [];

  constructor() {}

  ngOnInit() {}
}
