import { Component, Input, OnInit } from '@angular/core';
import { AccountInterface, FamilyInterface, AccountTypeEnum } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kc-account-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class AccountCardComponent implements OnInit {
  @Input() family!: FamilyInterface;
  @Input() account!: AccountInterface;

  AccountTypeEnum = AccountTypeEnum;
  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor() { }

  ngOnInit() {}

}
