<div class="family-card" expand="block" *ngIf="family">
  <ion-text class="card-title ion-text-left">{{ family.name }}</ion-text>
  <div class="content">
    <ion-text class="card-balance-title ion-text-left">Funds in Family</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ family.country.currency.symbol }} {{ family.balance | number:'1.0-2' }}</ion-text>

    <br>

    <ion-text class="card-balance-title ion-text-left">Available Funds</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ family.account.country.currency.symbol }} {{ family.account.balance | number:'1.0-2' }}</ion-text>

    <br>

    <ion-text class="card-balance-title ion-text-left">Goal Savings</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ family.account.country.currency.symbol }} {{ family.account.goalreached ? ( family.account.goalreached | number:'1.0-2' ) : '0' }} / {{ family.account.goaltarget ? ( family.account.goaltarget | number:'1.0-2' ) : '0' }}</ion-text>

    <br>

    <ion-text class="card-balance-title ion-text-left">Investments</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ family.account.country.currency.symbol }} {{ family.account.investment ? ( family.account.investment | number:'1.0-2' ) : '0' }}</ion-text>

    <div class="card-members">
      <img class="family-member" *ngFor="let kiddy_account of family.kiddy_accounts" [src]="( kiddy_account.kiddy?.avatarversion ) ? ( staticBaseUrl + '/profiles/' + kiddy_account.kiddy?.id + '/avatars/' + kiddy_account.kiddy?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/avatar.svg' )"/>
    </div>
  </div>
</div>

<div class="family-card" expand="block" *ngIf="account">
  <ion-text class="card-title ion-text-left">{{ account.name }}</ion-text>
  <div class="content">
    <ion-text class="card-balance-title ion-text-left">Balance</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ account.country.currency.symbol }} {{ account.balance | number:'1.0-2' }}</ion-text>

    <br>

    <ion-text class="card-balance-title ion-text-left">Goal Savings</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ account.country.currency.symbol }} {{ account.goalreached ? ( account.goalreached | number:'1.0-2' ) : '0' }} / {{ account.goaltarget ? ( account.goaltarget | number:'1.0-2' ) : '0' }}</ion-text>

    <br>

    <ion-text class="card-balance-title ion-text-left">Investments</ion-text>
    <ion-text class="card-balance-amount ion-text-left">{{ account.country.currency.symbol }} {{ account.investment ? ( account.investment | number:'1.0-2' ) : '0' }}</ion-text>

    <div class="card-members">
      <img class="family-member" *ngIf="account.type === AccountTypeEnum.Kiddy" [src]="( account.kiddy?.avatarversion ) ? ( staticBaseUrl + '/profiles/' + account.kiddy?.id + '/avatars/' + account.kiddy?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/avatar.svg' )"/>
    </div>
  </div>
</div>
