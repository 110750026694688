<div class="container ion-padding" routerDirection="root" [routerLink]="route">
  <div class="card">
      <div class="card-inner">
          <div class="front">
              <img src="{{ staticBaseUrl + '/payment-card/map.png' }}" class="map-img">
              <div class="row">
                  <img src="{{ staticBaseUrl + '/payment-card/chip.png' }}" width="60px">
                  <img src="{{ staticBaseUrl + '/payment-card/visa.png' }}" width="60px">
              </div>
              <div class="row card-no">
                  <p>5244</p>
                  <p>2150</p>
                  <p>8252</p>
                  <p>6420</p>
              </div>
              <div class="row card-holder">
                  <p>CARD HOLDER</p>
                  <p>VALID TILL</p>
              </div>
              <div class="row name">
                  <p>{{ payment_card.name }}</p>
                  <p>10 / 25</p>
              </div>
          </div>
          <div class="back">
              <img src="{{ staticBaseUrl + '/payment-card/map.png' }}" class="map-img">
              <div class="bar"></div>
              <div class="row card-cvv">
                  <div>
                      <img src="{{ staticBaseUrl + '/payment-card/pattern.png' }}">
                  </div>
                  <p>824</p>
              </div>
              <div class="row card-text">
                  <p>Powered by ABC XYZ Inc.</p>
              </div>
              <div class="row signature">
                  <p>CUSTOMER SIGNATURE</p>
                  <img src="{{ staticBaseUrl + '/payment-card/visa.png' }}" width="80px">
              </div>
          </div>
      </div>
  </div>
</div>