import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, NoAuthGuard, KiddyRoleGuard, FamilyRoleGuard } from './core/guards';

const routes: Routes = [
  {
    path: '',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'stock',
    loadChildren: () => import('./pages/stock/stock.module').then( m => m.StockPageModule)
  },
  {
    path: 'login',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'sign-up',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./pages/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'forgot-pin',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./pages/forgot-pin/forgot-pin.module').then( m => m.ForgotPinPageModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'family',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/family/family.module').then( m => m.FamilyPageModule)
  },
  {
    path: 'families',
    canActivate: [AuthGuard, FamilyRoleGuard],
    loadChildren: () => import('./pages/families/families.module').then( m => m.FamiliesPageModule)
  },
  {
    path: 'kiddy',
    canActivate: [AuthGuard, KiddyRoleGuard],
    loadChildren: () => import('./pages/kiddy/kiddy.module').then( m => m.KiddyPageModule)
  },
  {
    path: 'kiddy-business',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/kiddy-business/kiddy-business.module').then( m => m.KiddyBusinessPageModule)
  },
  {
    path: 'kiddy-businesses',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/kiddy-businesses/kiddy-businesses.module').then( m => m.KiddyBusinessesPageModule)
  },
  {
    path: 'schools',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/schools/schools.module').then( m => m.SchoolsPageModule)
  },
  {
    path: 'school',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/school/school.module').then( m => m.SchoolPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pages/not-found/not-found.module').then( m => m.NotFoundPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
