import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VerifyService {
  constructor(
    private http: HttpClient,
  ) { }

  validatePin(pin: string) {
    return this.http.post(`${environment.apiBaseUrl}/verify/pin`, { pin : pin });
  }

  requestPhoneOTP(phone: string) {
    return this.http.post(`${environment.apiBaseUrl}/verify/user/request-phone-otp`, 
      {
        phone: phone
      }
    );
  }

  verifyPhoneOTP(phone: string, code: string) {
    return this.http.post(`${environment.apiBaseUrl}/verify/user/verify-phone-otp`, 
      {
        phone: phone,
        code: code,        
      }
    );
  }

  requestEmailOTP(email: string) {
    return this.http.post(`${environment.apiBaseUrl}/verify/user/request-email-otp`, 
      {
        email: email
      }
    );
  }

  verifyEmailOTP(email: string, code: string) {
    return this.http.post(`${environment.apiBaseUrl}/verify/user/verify-email-otp`, 
      {
        email: email,
        code: code,        
      }
    );
  }

  requestAccountPhoneOTP(phone: string) {
    return this.http.post(`${environment.apiBaseUrl}/verify/account/user/add-phone/get-otp`, 
      {
        phone: phone
      }
    );
  }

  verifyAccountPhoneOTP(phone: string, code: string) {
    return this.http.post(`${environment.apiBaseUrl}/verify/account/user/add-phone/validate-otp`, 
      {
        phone: phone,
        code: code,        
      }
    );
  }

  requestAccountEmailOTP(email: string) {
    return this.http.post(`${environment.apiBaseUrl}/verify/account/user/add-email/get-otp`, 
      {
        email: email
      }
    );
  }

  verifyAccountEmailOTP(email: string, code: string) {
    return this.http.post(`${environment.apiBaseUrl}/verify/account/user/add-email/validate-otp`, 
      {
        email: email,
        code: code,        
      }
    );
  }

}
