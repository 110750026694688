import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, from, mergeMap, throttleTime, filter, timer } from 'rxjs';
import { GetResult, Preferences } from '@capacitor/preferences';
import { ModalController } from '@ionic/angular';
import { UserPinComponent } from 'src/app/components/user/pin/pin.component';
import { AuthService } from '../auth/auth.service';
import { ToastService } from '../notification/toast.service';
import { UserInterface } from '@kiddy-cash/common';
import { VerifyService } from '../verify/verify.service';

@Injectable({
  providedIn: 'root'
})
export class LastActiveService {
  private modal!: HTMLIonModalElement;
  user: UserInterface | null | undefined;

  private localStorageKey: string = '__lastActive';
  private events: string[] = ['keydown', 'click', 'wheel', 'mousemove'];
  private recordTimeoutMs: number = 1000;

  private inactivityPinRequestTimeoutSecs: number = 60 * 10; // 10 mins
  private timerTickMs: number = 500;

  public _$pin = new BehaviorSubject<string | undefined>(undefined);
  public _$pinProcessing = new BehaviorSubject<boolean>(false);
  public _$pinProcessingMessage = new BehaviorSubject<string>('Enter Your Pin');
  public _$pinProcessingStatus = new BehaviorSubject<string>('');
  public _$lastActive = new BehaviorSubject<Date>(new Date());
  public _$pinValidated = new BehaviorSubject<boolean>(false);

  constructor(
    private modalCtrl: ModalController,
    private authService: AuthService,
    private verifyService: VerifyService,
    private toastService: ToastService,
    ) {}

  public async setUp() {
    this.authService._$user.subscribe((user: UserInterface | null | undefined) => {
      this.user = user;
    });

    from(this.events).pipe(mergeMap(event => fromEvent(document, event)), throttleTime(this.recordTimeoutMs)).subscribe(_ => this.recordLastActiveDate());
    
    const modal = await this.setUpModal()
    this.setUpPinSubscription();

    const storedToken:GetResult = await Preferences.get({ key: 'access_token' });
    const token = storedToken.value;
    if(token) modal.present();

    timer(0, this.timerTickMs)
      .pipe(filter(isUser => this.user ? true : false))
      .subscribe(isUser => {
        const currentDate = new Date();
        const lastActiveDate = this._$lastActive.value;

        const secondsDifference = Math.abs(currentDate.getTime() - lastActiveDate.getTime())/1000;

        if (secondsDifference > this.inactivityPinRequestTimeoutSecs) {
          this.openModal();
        }
      });
  }

  private async setUpModal() {
    this.modal = await this.modalCtrl.create({
      component: UserPinComponent,
      canDismiss: false,
      cssClass: 'width-100-height-100'
    });

    this._$pinProcessing.next(false);
    this._$pinProcessingMessage.next('Enter Your Pin');
    this._$pinProcessingStatus.next('');
    this._$pinValidated.next(false);

    return this.modal
  }

  private setUpPinSubscription() {
    this._$pin.subscribe((pin: string | undefined) => {
      if(!pin) return;

      this._$pinProcessing.next(true);
      this._$pinProcessingMessage.next('Enter Your Pin');
      this._$pinProcessingStatus.next('');
      this._$pinValidated.next(false);

      this.verifyService.validatePin(pin).subscribe((res: any) => {
        const token = res.token;
        this.authService.decodeAndStoreToken(token);

        this._$pinProcessing.next(false);
        this._$pinProcessingMessage.next('Pin Validated Successfully');
        this._$pinProcessingStatus.next('success');
        this._$pinValidated.next(true);

        this.deletePin();

        this.modal.canDismiss = true;
        this.modal.dismiss();

        this.setUpModal();

        this.toastService.presentToast('Pin Validated Successfully', 'success');
      },
      (err: any) => {
        this._$pinProcessing.next(false);
        this._$pinProcessingMessage.next(err.error.message);
        this._$pinProcessingStatus.next('danger');
        this._$pinValidated.next(false);
      })
    });
  }

  private async recordLastActiveDate() {
    const currentDate = new Date(); 

    await Preferences.set({
      key: this.localStorageKey,
      value: currentDate.toString()
    });

    this._$lastActive.next(currentDate);
  }

  private async getLastActiveFromLocalStorage(): Promise<Date | null> {
    const ret:GetResult = await Preferences.get({ key: this.localStorageKey });
    const dateString = ret.value;

    if (!dateString) {
      return null;
    }

    return new Date(dateString);
  }

  openModal() {
    if(this.modal?.isOpen) return;
    this.modal.canDismiss = false;
    this.modal.present();
  }

  dismissPinModal() {
    if(!this.modal?.isOpen) return;
    this.modal.dismiss();
  }

  setPin(pin: string) {
    this._$pin.next(pin);
  }

  deletePin() {
    this._$pin.next(undefined);
  }

}