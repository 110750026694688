<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirm()" [strong]="true">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-grid>

    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-col size-xl="6" size-lg="6" size-sm="6" size="12">
        <ion-text color="amber">
          <h3>Review Transaction</h3>
        </ion-text>
      </ion-col>
    </ion-row>

    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-col size-xl="6" size-lg="6" size-sm="6" size="12">

        <ion-grid>
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col class="ion-no-padding ion-text-center">
              <pre>{{ requestData | json }}</pre>
            </ion-col>
          </ion-row>

          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col class="ion-no-padding ion-text-center">
              <ion-button (click)="confirm()" shape="round" color="success">Transact</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>

      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>