<form [formGroup]="familyLoanEditForm" (ngSubmit)="submitForm()">
  <ion-card mode="md">
    <ion-fab horizontal="end">
      <ion-fab-button size="small" color="warning" *ngIf="!isEditting" (click)="startEditing()">
        <ion-icon name="create"></ion-icon>
      </ion-fab-button>
      <ion-fab-button size="small" color="danger" *ngIf="isEditting" (click)="stopEditing()">
        <ion-icon name="close"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-card-header>
      <ion-card-title>
        <ng-container *ngIf="!isEditting">
          {{ loan.purpose }}
        </ng-container>
        <ng-container *ngIf="isEditting">
          <ion-list>
            <ion-item lines="none">
              <ion-input
                type="text"
                label="Purpose"
                labelPlacement="floating"
                formControlName="purpose"
                [counter]="true" 
                minlength="3"
                maxlength="100"
                [value]="loan.purpose"
              ></ion-input>
            </ion-item>
          </ion-list>
        </ng-container>
      </ion-card-title>
      <ion-card-subtitle>
        <ng-container *ngIf="!isEditting">
          {{ loan.to_account.country.currency.code }} {{ loan.amount | number:'1.0-2' }}
        </ng-container>
      </ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      <ion-grid class="ion-no-padding" *ngIf="!isEditting">
        <ion-row>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Balance</ion-label>
              </ion-list-header>
              <ion-item>
                {{ loan.to_account.country.currency.code }} {{ loan.balance | number:'1.0-2' }}
              </ion-item>
            </ion-list>   
          </ion-col>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Interest</ion-label>
              </ion-list-header>
              <ion-item>
                {{ loan.to_account.country.currency.code }} {{ loan.interest | number:'1.0-2' }}
              </ion-item>
            </ion-list>   
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Issued</ion-label>
              </ion-list-header>
              <ion-item>
                {{ loan.issued_on | date: 'longDate' }}
              </ion-item>
            </ion-list>   
          </ion-col>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Notifications Frequency</ion-label>
              </ion-list-header>
              <ion-item>
                {{ loan.notify }}
              </ion-item>
            </ion-list>   
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="6" *ngIf="loan.repayed_on">
            <ion-list>
              <ion-list-header>
                <ion-label>Repayed On</ion-label>
              </ion-list-header>
              <ion-item>
                {{ loan.repayed_on | date: 'longDate' }}
              </ion-item>
            </ion-list>   
          </ion-col>
          <ion-col size="6" *ngIf="loan.cancelled_on">
            <ion-list>
              <ion-list-header>
                <ion-label>Cancelled On</ion-label>
              </ion-list-header>
              <ion-item>
                {{ loan.cancelled_on | date: 'longDate' }}
              </ion-item>
            </ion-list>   
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid class="ion-no-padding" *ngIf="isEditting">
        <ion-row>
          <ion-col size="12">
            <ion-list>
              <ion-item lines="none">
                <ion-select label="Notifications Frequency" label-placement="floating" value="{{ loan.notify }}">
                  <ion-select-option *ngFor="let notify of NotifyFrequencyEnumValues" [value]="notify">{{ notify }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>

    <ion-button *ngIf="isEditting" type="submit" expand="block" color="darkpurple">Update</ion-button>
  </ion-card>
</form>
