<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()">Cancel</ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirmChanges()">Done</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar (ionInput)="searchbarInput($event)"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content color="light" class="ion-padding">
  <ion-radio-group (ionChange)="radioChange($event)">
    <ion-list id="modal-list" [inset]="true">
      <ion-item *ngFor="let school of filteredSchools; trackBy: trackSchools">
        <ion-thumbnail slot="start" class="ion-margin-end">

        </ion-thumbnail>
        <ion-radio [value]="school.id">{{ school.name }}</ion-radio><br />
      </ion-item>
    </ion-list>
  </ion-radio-group>
</ion-content>