<ion-item lines="none" class="task-main-item" routerDirection="root" [routerLink]="route">
  <ion-row class="tasks-row">
    <ion-col size="12" class="task-ion-body">
      <div class="loan-sub">
        <div>
          <ion-item lines="none" class="task-ion-item"
            ><ion-text class="task-title" color="primary">{{
              loan.purpose
            }}</ion-text></ion-item
          >

          <ion-item lines="none" class="task-ion-item"
            ><ion-text class="task-body" color="amber"
              ><span class="loan-text">Loan for</span>
              {{ loan.to_account.kiddy?.firstname }}
              {{ loan.to_account.kiddy?.lastname }}</ion-text
            ></ion-item
          >
        </div>
        <div class="reward-div">
          <ion-text class="reward-text">Amount due</ion-text>

          <ion-text class="reward-amount"
            >{{ loan.from_account.country.currency.code }}
            {{ loan.balance | number:'1.0-2' }}</ion-text
          >
        </div>
      </div>
      <div class="loan-sub-bottom">
        <div class="loan-ion-item">
          <ion-text class="task-date" color="primary"
            >{{ loan.notify }}</ion-text
          >
          <ion-text class="description-bottom">Reminder</ion-text>
        </div>
        <div class="loan-ion-item">
          <ion-text class="task-date" color="primary"
            >{{ loan.from_account.country.currency.code }}
            {{ loan.amount | number:'1.0-2' }}</ion-text
          >
          <ion-text class="description-bottom">Amount</ion-text>
        </div>
        <div class="loan-ion-item">
          <ion-text class="task-date" color="primary"
            >{{ loan.from_account.country.currency.code }}
            {{ loan.interest | number:'1.0-2' }}</ion-text
          >
          <ion-text class="description-bottom">Interest</ion-text>
        </div>
        <div class="loan-ion-item">
          <ion-text class="task-date" color="primary">{{
            loan.issued_on | date : "mediumDate"
          }}</ion-text>
          <ion-text class="description-bottom">Issued</ion-text>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-item>
