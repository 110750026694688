import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'src/app/services';
import { jwtDecode, JwtPayload } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      return new Promise((resolve) => {
        this.authService._$token.subscribe((token: string | null | undefined) => {
          if(!token){
            // Allow the route
            resolve(true);
          } else {
            const decodedToken  = jwtDecode<JwtPayload>(token);
            const isExpired: boolean = decodedToken.exp ? Date.now() >= decodedToken.exp * 1000 : false;
            if (isExpired) {
              // Allow the route
              resolve(true);

              // But also log the user out, i.e clear storage
              this.authService.logout();
            } else {
              // Do not allow the route
              resolve(false);

              // then navigate to dashboard
              this.router.navigate(['/dashboard']);
            }
          }
        });
      });
  }

}
