import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StockInterface } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockService {
  public _$stocks = new BehaviorSubject<StockInterface[]>([]);

  constructor(private http: HttpClient) { }

  public async setUp() {
    this.fetchStocks();
  }

  fetchStocks() {
    this.getStocks().subscribe((res) => {
      const stocks: StockInterface[] = res.data.stocks;
      this._$stocks.next(stocks)
    });
  }

  getStocks() {
    return this.http.get<any>(`${environment.dataBaseUrl}/stocks`);
  }

  getStock(stock: number) {
    return this.http.get<any>(`${environment.dataBaseUrl}/stocks/${stock}`);
  }

}
