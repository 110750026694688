import { Component, Input, OnInit } from '@angular/core';
import { FamilyInterface } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kc-family-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class FamilyCardComponent  implements OnInit {
  @Input() family!: FamilyInterface;

  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor() { }

  ngOnInit() {}

}
