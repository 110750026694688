<ion-content color="background">
  <div class="mars"></div>
  <img [src]="staticBaseUrl + '/404/404.svg'" class="logo-404" />
  <img [src]="staticBaseUrl + '/404/meteor.svg'" class="meteor" />
  <p class="title">Oh no!!</p>
  <p class="subtitle">
    You’re either misspelling the URL <br /> or requesting a page that's no longer here.
  </p>
  <div align="center">
    <a class="btn-back" routerDirection="root" routerLink="..">Back to previous page</a>
  </div>
  <img [src]="staticBaseUrl + '/404/astronaut.svg'" class="astronaut" />
  <img [src]="staticBaseUrl + '/404/spaceship.svg'" class="spaceship" />
</ion-content>
