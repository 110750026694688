<ion-content class="ion-padding">
  <ion-grid>
    <ion-row class="ion-align-items-center ion-justify-content-center min-height-95-vh">
      <ion-col size-xl="6" size-lg="6" size-sm="6" size="12">

        <ion-grid>
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size="auto">
              <ion-avatar>
                <img alt="avatar" src="{{ user?.profile?.avatarversion ? ( staticBaseUrl + '/profiles/' + user?.profile?.id + '/avatars/' + user?.profile?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/avatar.svg' ) }}" class="profile-pic"/>
              </ion-avatar>
            </ion-col>
          </ion-row>

          <ion-row class="ion-align-items-center ion-justify-content-center" *ngIf="user?.profile?.firstname">
            <ion-col size="auto">
              <ion-text>
                {{ user?.profile?.firstname }} {{ user?.profile?.lastname }}
              </ion-text>
            </ion-col>
          </ion-row>

          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size="auto">
              <ion-text>
                {{ user?.phone ? formatInternationalPhoneNumber(user?.phone) : user?.email }}
              </ion-text>
            </ion-col>
          </ion-row>

          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size="auto">
              <ion-text [color]="pinProcessingStatus" *ngIf="!pinProcessing">
                <p>{{ pinProcessingMessage }}</p>
              </ion-text>

              <ion-item *ngIf="pinProcessing" class="background-none" lines="none">
                <ion-label>Processing</ion-label>
                <ion-spinner name="dots"></ion-spinner>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size="auto" class="ion-no-padding">
              <ion-input #pinInput  
                class="ion-text-center" 
                type="password" 
                (ionInput)="inputChange($event)"
                size="4"
                minlength="4"
                maxlength="4"
                autocomplete="off" 
              ></ion-input>
            </ion-col>
          </ion-row>

          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size="auto">
              <ion-button expand="block" size="large" (click) ="clickDigit(1)" color="darkpurple">1</ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button expand="block" size="large" (click) ="clickDigit(2)" color="darkpurple">2</ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button expand="block" size="large" (click) ="clickDigit(3)" color="darkpurple">3</ion-button>
            </ion-col>
          </ion-row>

          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size="auto">
              <ion-button expand="block" size="large" (click) ="clickDigit(4)" color="darkpurple">4</ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button expand="block" size="large" (click) ="clickDigit(5)" color="darkpurple">5</ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button expand="block" size="large" (click) ="clickDigit(6)" color="darkpurple">6</ion-button>
            </ion-col>
          </ion-row>

          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size="auto">
              <ion-button expand="block" size="large" (click) ="clickDigit(7)" color="darkpurple">7</ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button expand="block" size="large" (click) ="clickDigit(8)" color="darkpurple">8</ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button expand="block" size="large" (click) ="clickDigit(9)" color="darkpurple">9</ion-button>
            </ion-col>
          </ion-row>

          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size="auto">
              <ion-button expand="block" size="medium" (click) ="bioAuth()" fill="clear">
                <ion-icon name="finger-print-outline"></ion-icon>
              </ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button expand="block" size="large" (click) ="clickDigit(0)" color="darkpurple">0</ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button expand="block" size="medium" (click) ="deleteLastDigit()" fill="clear">
                <ion-icon name="backspace-outline"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size="auto">
              <ion-button size="small" (click) ="forgotPin()" fill="clear">
                <small>Forgot Pin?</small>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>

      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>