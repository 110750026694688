<form [formGroup]="familyInvestmentEditForm" (ngSubmit)="submitForm()">
  <ion-card mode="md">
    <ion-fab horizontal="end">
      <ion-fab-button size="small" color="warning" *ngIf="!isEditting" (click)="startEditing()">
        <ion-icon name="create"></ion-icon>
      </ion-fab-button>
      <ion-fab-button size="small" color="danger" *ngIf="isEditting" (click)="stopEditing()">
        <ion-icon name="close"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-card-header>
      <ion-card-title>
        <ng-container *ngIf="!isEditting">
          {{ investment.stock.name }}
        </ng-container>
      </ion-card-title>
      <ion-card-subtitle>
        <ng-container *ngIf="!isEditting">
          {{ investment.kiddy_account.country.currency.code }} {{ investment.value | number:'1.0-2' }}
        </ng-container>
      </ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      <ion-grid class="ion-no-padding" *ngIf="!isEditting">
        <ion-row>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Active</ion-label>
              </ion-list-header>
              <ion-item>
                {{ investment.active }}
              </ion-item>
            </ion-list>   
          </ion-col>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Notifications Frequency</ion-label>
              </ion-list-header>
              <ion-item>
                {{ investment.notify }}
              </ion-item>
            </ion-list>   
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Created On</ion-label>
              </ion-list-header>
              <ion-item>
                {{ investment.creation_date | date: 'longDate' }}
              </ion-item>
            </ion-list>   
          </ion-col>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Updated On</ion-label>
              </ion-list-header>
              <ion-item>
                {{ investment.updated_on | date: 'longDate' }}
              </ion-item>
            </ion-list>   
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid class="ion-no-padding" *ngIf="isEditting">
        <ion-row>
          <ion-col size="12">
            <ion-list>
              <ion-item lines="none">
                <ion-input
                  type="text"
                  label="Active"
                  labelPlacement="floating"
                  formControlName="active"
                  [value]="investment.active"
                ></ion-input>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-list>
              <ion-item lines="none">
                <ion-select label="Status" label-placement="floating" value="{{ investment.status }}">
                  <ion-select-option *ngFor="let status of InvestmentStatusEnumValues" [value]="status">{{ status }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-list>
              <ion-item lines="none">
                <ion-select label="Notifications Frequency" label-placement="floating" value="{{ investment.notify }}">
                  <ion-select-option *ngFor="let notify of NotifyFrequencyEnumValues" [value]="notify">{{ notify }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>

    <ion-button *ngIf="isEditting" type="submit" expand="block" color="darkpurple">Update</ion-button>
  </ion-card>
</form>
