import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AccountCardComponent } from './card/card.component';
import { AccountTransactionsListComponent } from './transactions-list/transactions-list.component';

@NgModule({
  declarations: [
    AccountCardComponent,
    AccountTransactionsListComponent,
  ],
  exports: [
    AccountCardComponent,
    AccountTransactionsListComponent,
  ],
  imports: [
    CommonModule,
    DatePipe, 
    IonicModule, 
    RouterModule,
  ]
})
export class AccountModule { }
