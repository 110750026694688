import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UserPinComponent } from './pin/pin.component';

@NgModule({
  declarations: [UserPinComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [UserPinComponent]
})
export class UserModule { }
