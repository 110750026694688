import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'kc-transaction-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class TransactionCardModalComponent  implements OnInit {
  @Input() requestData: any;
  @Input() fee: number = 0;

  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor(private modalCtrl: ModalController) {}

  async ngOnInit() {}

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(null, 'confirm');
  }
}
