<form [formGroup]="kiddyGoalForm" (ngSubmit)="submitForm()">
  <ion-card mode="md">
    <ion-fab horizontal="end">
      <ion-fab-button size="small" color="warning" *ngIf="!isEditting" (click)="startEditing()">
        <ion-icon name="create"></ion-icon>
      </ion-fab-button>
      <ion-fab-button size="small" color="danger" *ngIf="isEditting" (click)="stopEditing()">
        <ion-icon name="close"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-card-header>
      <ion-card-title>
        <ng-container *ngIf="!isEditting">
          {{ goal.name }}
        </ng-container>
        <ng-container *ngIf="isEditting">
          <ion-list>
            <ion-item lines="none">
              <ion-input
                type="text"
                label="Name"
                labelPlacement="floating"
                formControlName="name"
                [counter]="true" 
                minlength="3"
                maxlength="100"
                [value]="goal.name"
              ></ion-input>
            </ion-item>
          </ion-list>
        </ng-container>
      </ion-card-title>
      <ion-card-subtitle>
        <ng-container *ngIf="!isEditting">
          {{ goal.kiddy_account.country.currency.code }} {{ goal.target | number:'1.0-2' }}
        </ng-container>
        <ng-container *ngIf="isEditting">
          <ion-list>
            <ion-item lines="none">
              <ion-input
                type="number"
                label="Target in {{ goal.kiddy_account.country.currency.symbol }}"
                labelPlacement="floating"
                formControlName="target"
                [value]="goal.target"
              ></ion-input>
            </ion-item>
          </ion-list>
        </ng-container>
      </ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      <ion-grid class="ion-no-padding" *ngIf="!isEditting">

        <ion-row>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Reached</ion-label>
              </ion-list-header>
              <ion-item>
                {{ goal.kiddy_account.country.currency.code }} {{ goal.reached | number:'1.0-2' }}
              </ion-item>
            </ion-list>   
          </ion-col>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Percetage</ion-label>
              </ion-list-header>
              <ion-item>
                {{ goal.ratio | number:'1.0-2' }}%
              </ion-item>
            </ion-list>   
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Active</ion-label>
              </ion-list-header>
              <ion-item>
                {{ goal.active }}
              </ion-item>
            </ion-list>   
          </ion-col>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Notifications Frequency</ion-label>
              </ion-list-header>
              <ion-item>
                {{ goal.notify }}
              </ion-item>
            </ion-list>   
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Started</ion-label>
              </ion-list-header>
              <ion-item>
                {{ goal.creation_date | date: 'longDate' }}
              </ion-item>
            </ion-list>   
          </ion-col>
          <ion-col size="6">
            <ion-list>
              <ion-list-header>
                <ion-label>Updated</ion-label>
              </ion-list-header>
              <ion-item>
                {{ goal.updated_on | date: 'longDate' }}
              </ion-item>
            </ion-list>   
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid class="ion-no-padding" *ngIf="isEditting">

        <ion-row>
          <ion-col size="12" *ngIf="goal.imageversion">
            <img alt="image" src="{{ staticBaseUrl + '/goals/' + goal.id + '/images/' + goal.imageversion + '.jpeg' }}" />
          </ion-col>
          <ion-col size="12">
            <ion-list>
              <ion-item lines="none">
                <ion-label position="stacked" color="primary">Choose an image</ion-label>
                <input #fileInput type="file" accept="image/png, image/jpeg, image/jpg">
              </ion-item>
            </ion-list>

            <div #cropperContainer class="cropper-container"></div>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-list>
              <ion-item lines="none">
                <ion-select label="Status" label-placement="floating" value="{{ goal.status }}">
                  <ion-select-option *ngFor="let status of GoalStatusEnumValues" [value]="status">{{ status }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-list>
              <ion-item lines="none">
                <ion-select label="Notifications Frequency" label-placement="floating" value="{{ goal.notify }}">
                  <ion-select-option *ngFor="let notify of NotifyFrequencyEnumValues" [value]="notify">{{ notify }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>

      </ion-grid>
    </ion-card-content>

    <ion-button *ngIf="isEditting" type="submit" expand="block" color="darkpurple">Update</ion-button>
  </ion-card>
</form>
