import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ChildInformationComponent } from './child-information.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from '../select/select.module';
import { MaskitoDirective, MaskitoPipe } from '@maskito/angular';

@NgModule({
  declarations: [ChildInformationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IonicModule,
    SelectModule,
    MaskitoDirective, 
    MaskitoPipe, 
  ],
  exports: [
   ChildInformationComponent
  ]
})
export class ChildInformationModule { }
