import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services';
import { jwtDecode, JwtPayload } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService, 
    private router: Router,
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      return new Promise((resolve) => {
        this.authService._$token.subscribe((token: string | null | undefined) => {
          if(token){
            const decodedToken  = jwtDecode<JwtPayload>(token);
            const isExpired: boolean = decodedToken.exp ? Date.now() >= decodedToken.exp * 1000 : true;
            if (isExpired) {
              this.authService.logout();
              // Do not allow the route
              resolve(false);

              // then navigate to login
              this.router.navigate(['/login']);
            } else {
              // Allow the route
              resolve(true);
            }
          } else {
            // Do not allow the route
            resolve(false);
          }
        });
      });
  }
  
}
