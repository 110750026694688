import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FamilyService, LoadingService, ToastService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class AccountIntegrationGuard implements CanActivate {
  constructor(
    private familyService: FamilyService,
    private loadingService: LoadingService,
    private router: Router, 
    private toastService: ToastService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      this.loadingService.present();

      const family_id = route.paramMap.get('family_id') as string;

      return new Promise((resolve) => {
        this.familyService.getActiveFamilyAccountIntegration(family_id).subscribe((res) => {
          this.loadingService.dismiss();
          const account_integration = res.data.account_integration;

          if(!account_integration) {
            // Disallow the route
            resolve(false);

            this.toastService.presentToast('Please setup a bank account integration first', 'medium');

            // then navigate to family integration page
            this.router.navigate(['/family', family_id, 'integration']);
          } else {
            // Allow the route
            resolve(true);
          }
        },
        (err: any) => {
          // Disallow the route
          resolve(false);

          this.loadingService.dismiss()
          this.toastService.presentToast(err.error.message, 'danger');
        })
      });
  }
  
}
