<ion-item lines="none" class="goal-main-item" routerDirection="root" [routerLink]="route">
  <ion-row class="goals-row">
    <ion-col size="12" class="goal-ion-body">
      <div class="goal-sub">
        <div>
          <ion-item lines="none" class="goal-ion-item">
            <ion-thumbnail aria-hidden="true" slot="start" *ngIf="goal.imageversion">
              <img width="56" alt="" src="{{ staticBaseUrl + '/goals/' + goal.id + '/images/' + goal.imageversion + '.jpeg' }}" />
            </ion-thumbnail>

            <ion-text class="goal-title" color="primary">{{ goal.name }}</ion-text>
          </ion-item>

          <ion-item lines="none" class="goal-ion-item"
            ><ion-text class="goal-body" color="amber"
              ><span class="goal-text">Savings by</span>
              {{ goal.kiddy_account.kiddy?.firstname }}
              {{ goal.kiddy_account.kiddy?.lastname }}</ion-text
            ></ion-item
          >
        </div>
        <div class="reward-div">
          <ion-text class="reward-text">Target</ion-text>

          <ion-text class="reward-amount"
            >{{ goal.kiddy_account.country.currency.code }}
            {{ goal.target | number:'1.0-2' }}</ion-text
          >
        </div>
      </div>
      <div class="goal-sub-bottom">
        <div class="goal-ion-item">
          <ion-text class="goal-date" color="primary"
            >{{ goal.notify }}</ion-text
          >
          <ion-text class="description-bottom">Reminder</ion-text>
        </div>
        <div class="goal-ion-item">
          <ion-text class="goal-date" color="primary"
            >{{ goal.kiddy_account.country.currency.code }}
            {{ goal.reached | number:'1.0-2' }}</ion-text
          >
          <ion-text class="description-bottom">Reach</ion-text>
        </div>
        <div class="goal-ion-item">
          <ion-text class="goal-date" color="primary"
            >
            {{ goal.ratio | number:'1.0-2' }}%</ion-text
          >
          <ion-text class="description-bottom">Percentage</ion-text>
        </div>
        <div class="goal-ion-item">
          <ion-text class="goal-date" color="primary">{{
            goal.creation_date | date : "mediumDate"
          }}</ion-text>
          <ion-text class="description-bottom">Started On</ion-text>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-item>
