import { Component, Input, OnInit } from '@angular/core';
import { InvestmentInterface } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kc-investment-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class InvestmentListItemComponent  implements OnInit {
  @Input() investment!: InvestmentInterface;
  @Input() route: (string | undefined)[] = [];

  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor() { }

  ngOnInit() {}

}
